import React, {ChangeEvent, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox} from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { PaidService } from "interfaces/space";
import { useTranslate } from "@pankod/refine-core";
import { isPaidServiceTheSame, psIntersection, psRemoveAllFrom } from "utils";
import PaidServicesLabel from "./PaidServicesLabel";
import PaidServicesCreateFormModal from "./PaidServicesCreateFormModal";
import PaidServicesEditFormModal from "./PaidServicesEditFormModal";
import PaidServicesChipGroup from "./PaidServicesChipGroup";

interface GroupPackagesPaidServicesFormProps {
    groupPaidServices?: PaidService[]
    previous: PaidService[] | null
    onChange: (paidServices: PaidService[]) => void
}

const GroupPackagesPaidServicesForm: React.FC<GroupPackagesPaidServicesFormProps> = (props) => {
    const {groupPaidServices, previous, onChange} = props

    const t = useTranslate()
    const paidServicesGeneralTitle = t("updateSpace.spacePaidServicesTitlePackage")
    const paidServicesMandatorySubtitle = t("updateSpace.spacePaidServicesSubtitle")
    const addPaidServices = t("updateSpace.spacePaidServicesAdd")

    const [openPaidServices, setOpenPaidServices] = useState(false)
    const [openEditServiceModal, setOpenEditServiceModal] = useState(false)
    const [editIndex, setEditIndex] = useState(-1)
    const [editService, setEditService] = useState<PaidService | null>(null)

    const [paidServicesInSpace, setPaidServicesInSpace] = useState<PaidService[]>([])
    const [checkedPaidServicesInSpace, setCheckedPaidServicesInSpace] = useState<PaidService[]>([])
    const [checkedPaidServicesNotInSpace, setCheckedPaidServicesNotInSpace] = useState<PaidService[]>([])

    const closeEditDialogHandler = () => {
        setOpenEditServiceModal(false)
        setEditIndex(-1)
        setEditService(null)
    }

    const onServiceClickedHandler = (index: number, paidService: PaidService) => {
        setOpenEditServiceModal(true)
        setEditIndex(index)
        setEditService(paidService)
    }

    useEffect(() => {
        if (groupPaidServices !== undefined && groupPaidServices.length > 0) {
            const paidServicesInSpace = groupPaidServices
            if (previous !== null) { // space has amenitites and is editing package
                setPaidServicesInSpace([...paidServicesInSpace])
                setCheckedPaidServicesInSpace(psIntersection(paidServicesInSpace, previous))
                setCheckedPaidServicesNotInSpace(psRemoveAllFrom(previous, paidServicesInSpace))
            } else { // space has paid services and is new package
                setPaidServicesInSpace([...paidServicesInSpace])
                const mandatoryPaidServices = paidServicesInSpace.filter(paidService => paidService.mandatory)
                setCheckedPaidServicesInSpace(mandatoryPaidServices)
                // Do nothing with additional(checkedPaidServicesNotInSpace) paid services since is new package
            }
        } else {
            if (previous !== null) { // space paid services is empty and is editing package
                setCheckedPaidServicesNotInSpace([...previous])
            }
        }
    }, [])

    useEffect(() => {
        onChange([...checkedPaidServicesInSpace, ...checkedPaidServicesNotInSpace])
    }, [onChange, checkedPaidServicesInSpace, checkedPaidServicesNotInSpace])

    const onPaidServiceCheckedChanged = (event: ChangeEvent<HTMLInputElement>, checked: boolean, entry: PaidService) => {
        const newArray = [...checkedPaidServicesInSpace]
        if (checked) {
            newArray.push(entry)
        } else {
            newArray.splice(checkedPaidServicesInSpace.findIndex(paidService => isPaidServiceTheSame(paidService, entry)), 1)
        }
        setCheckedPaidServicesInSpace(newArray)
    }

    const onClosePaidServicesHandler = () => {
        setOpenPaidServices(false)
    }

    const onSavePaidService = (paidService: PaidService) => {
        setOpenPaidServices(false)
        const newArray = [...checkedPaidServicesNotInSpace]
        newArray.push(paidService)
        setCheckedPaidServicesNotInSpace(newArray)
    }

    const onSaveEditServiceHandler = (index: number, paidService: PaidService) => {
        const newArray = [...checkedPaidServicesNotInSpace]
        newArray[index] = paidService
        setCheckedPaidServicesNotInSpace(newArray)
    }

    const onAdditionalPaidServiceRemoved = (entry: PaidService) => {
        const newArray = [...checkedPaidServicesNotInSpace]
        newArray.splice(checkedPaidServicesNotInSpace.findIndex((paidService) => {
            return isPaidServiceTheSame(paidService, entry)
        }), 1)
        setCheckedPaidServicesNotInSpace(newArray)
    }

    const addPaidServicesButtonHandler = () => {
        setOpenPaidServices(true)
    }

    const mandatory = paidServicesInSpace.filter((paidService) => paidService.mandatory)
    const optional = paidServicesInSpace.filter((paidService) => !paidService.mandatory)

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography sx={{mt: 3}} variant='titleMedium'>{paidServicesGeneralTitle}</Typography>
        <Typography sx={{mt: 1}} variant='bodyMedium'>{paidServicesMandatorySubtitle}</Typography>
        {mandatory.length > 0 && <Grid container spacing={3} sx={{mt: 1}}>
                {mandatory.map((paidService) => {
                    return <Grid item md={4} key={`paid-service-${paidService.name}`} sx={{pt: '8px !important'}}>
                        <FormControlLabel 
                            control={<Checkbox checked={true} disabled/>} 
                            label={<PaidServicesLabel paidService={paidService}/>}
                        />
                    </Grid>
                })}
            </Grid>
        }
        {optional.length > 0 &&
            <Grid container spacing={3} sx={{mt: 1}}>
                {optional.map((paidService) => {
                    return <Grid item md={4} key={`paid-service-${paidService.name}`} sx={{pt: '8px !important'}}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkedPaidServicesInSpace.includes(paidService)}
                                    onChange={(event, checked) => {
                                        onPaidServiceCheckedChanged(event, checked, paidService)
                                    }}/>
                            }
                            label={<PaidServicesLabel paidService={paidService}/>}
                        />
                    </Grid>
                })}
            </Grid>}
        <PaidServicesCreateFormModal
            open={openPaidServices}
            onClose={onClosePaidServicesHandler}
            onSavePaidService={onSavePaidService}
            isPackage={true}
        />
        {editIndex !== -1 && editService !== null &&
            <PaidServicesEditFormModal
                open={openEditServiceModal}
                index={editIndex}
                paidService={editService}
                onSavePaidService={onSaveEditServiceHandler}
                onClose={closeEditDialogHandler}
                isPackage={true}
            />
        }
        <Button
            startIcon={<AddIcon/>}
            variant='text'
            sx={{
                mt: 2,
                width: 'max-content'
            }}
            onClick={addPaidServicesButtonHandler}
        >
            <Typography variant='labelLarge'>{addPaidServices}</Typography>
        </Button>
        {
            checkedPaidServicesNotInSpace.length > 0 && <Box sx={{mt: 1}}>
                <PaidServicesChipGroup
                    onServiceClicked={onServiceClickedHandler}
                    onServiceRemoved={onAdditionalPaidServiceRemoved}
                    paidServices={checkedPaidServicesNotInSpace}
                />
            </Box>
        }
    </Box>
}

export default GroupPackagesPaidServicesForm